import React from "react";

import {
  StyledContactDivider,
  StyledContactFbIcon,
  StyledContactForm,
  StyledContactFormBox,
  StyledContactFormContent,
  StyledContactFormWrapper,
  StyledContactMap,
} from "./contactForm.style";
import { StyledCenter } from "../../../utils/styledComponents/center.style";
import Form from "./form/form.component";
import Map from "./map/map.component";
import FbIcon from "../../../images/fbIconBlue.png";
import { LANG } from "../../../lang/lang";

const ContactForm = (): JSX.Element => {
  return (
    <StyledContactFormWrapper>
      <StyledCenter>
        <StyledContactFormContent>
          <StyledContactFormBox>
            <StyledContactForm>
              <Form errorsMessage={LANG.PL.forms.errors} />
            </StyledContactForm>
            <StyledContactMap>
              <Map />
            </StyledContactMap>
          </StyledContactFormBox>
          <StyledContactDivider href="https://www.facebook.com/voltspotspzoo">
            <StyledContactFbIcon src={FbIcon} />
            {LANG.PL.contactSubpage.dividerTitle}
          </StyledContactDivider>
        </StyledContactFormContent>
      </StyledCenter>
    </StyledContactFormWrapper>
  );
};

export default ContactForm;
