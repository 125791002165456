import React, { useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";

import {
  StyledCheckboxContainer,
  StyledCheckboxLabel,
  StyledCheckboxMsg,
  StyledErrorMessage,
  StyledErrorTextArea,
  StyledForm,
  StyledFormBtnBox,
  StyledFormCheckbox,
  StyledFormInput,
  StyledFormInputs,
  StyledFormItem,
  StyledFormLabel,
  StyledFormTextArea,
  StyledFormTextAreaBox,
  StyledFormtitle,
  StyledFormWrapper,
  StyledLinkButton,
  StyledMessage,
  StyledMessageBox,
} from "./form.style";
import Button from "../../../../components/button/button.component";
import { useFormData } from "../../../../shared/useFormData.hook";
import { Loader } from "../../../../utils/styledComponents/loader.style";
import { LANG } from "../../../../lang/lang";

type FormValues = {
  name: string;
  phone: number;
  email: string;
  zipCode: number;
  message: string;
  policy: boolean;
};

type ErrorMessageElement = {
  email: string;
  phone: string;
  required: string;
  zipCode: string;
};

type FormPropsType = {
  errorsMessage: ErrorMessageElement;
};

const Form = ({ errorsMessage }: FormPropsType): JSX.Element => {
  const [value, setValue] = useState(null);
  const sendData = useFormData(value);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const textarea = (
    <StyledFormTextAreaBox>
      <StyledFormLabel>{LANG.PL.forms.inputs.message}</StyledFormLabel>
      <StyledFormTextArea
        {...register("message", {
          required: errorsMessage.required,
        })}
      ></StyledFormTextArea>
      {errors.name && (
        <StyledErrorTextArea>{errors.name.message}</StyledErrorTextArea>
      )}
    </StyledFormTextAreaBox>
  );

  return (
    <>
      {sendData[1] && !sendData[2] && !sendData[0] && (
        <StyledMessageBox>
          <Loader />
        </StyledMessageBox>
      )}
      {!sendData[1] && !sendData[2] && sendData[0] && (
        <StyledMessageBox>
          <StyledMessage success={sendData[0]}>
            {LANG.PL.forms.succesMessage}
          </StyledMessage>
        </StyledMessageBox>
      )}
      {!sendData[1] && sendData[2] && !sendData[0] && (
        <StyledMessageBox>
          <StyledMessage success={false}>
            {LANG.PL.forms.errorMessage}
          </StyledMessage>
        </StyledMessageBox>
      )}
      {!sendData[1] && !sendData[0] && (
        <StyledFormWrapper onSubmit={handleSubmit(setValue)}>
          <StyledFormtitle>{LANG.PL.contactSubpage.formTitle}</StyledFormtitle>
          <StyledForm>
            <StyledFormInputs>
              <StyledFormItem>
                <StyledFormLabel>{LANG.PL.forms.inputs.name}</StyledFormLabel>
                <StyledFormInput
                  {...register("name", {
                    required: errorsMessage.required,
                  })}
                ></StyledFormInput>
                {errors.name && (
                  <StyledErrorMessage>{errors.name.message}</StyledErrorMessage>
                )}
              </StyledFormItem>
              <StyledFormItem>
                <StyledFormLabel>{LANG.PL.forms.inputs.phone}</StyledFormLabel>
                <StyledFormInput
                  {...register("phone", {
                    required: errorsMessage.required,
                    minLength: { value: 9, message: errorsMessage.phone },
                    pattern: {
                      value: /^\d+$/i,
                      message: errorsMessage.phone,
                    },
                  })}
                ></StyledFormInput>
                {errors.phone && (
                  <StyledErrorMessage>
                    {errors.phone.message}
                  </StyledErrorMessage>
                )}
              </StyledFormItem>
              <StyledFormItem>
                <StyledFormLabel>{LANG.PL.forms.inputs.email}</StyledFormLabel>
                <StyledFormInput
                  {...register("email", {
                    required: errorsMessage.required,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: errorsMessage.email,
                    },
                  })}
                ></StyledFormInput>
                {errors.email && (
                  <StyledErrorMessage>
                    {errors.email.message}
                  </StyledErrorMessage>
                )}
              </StyledFormItem>
              <StyledFormItem>
                <StyledFormLabel>
                  {LANG.PL.forms.inputs.zipCode}
                </StyledFormLabel>
                <StyledFormInput
                  {...register("zipCode", {
                    required: errorsMessage.required,
                    pattern: {
                      value: /^\d{2}(?:[-\s]\d{3})?$/i,
                      message: errorsMessage.zipCode,
                    },
                  })}
                ></StyledFormInput>
                {errors.zipCode && (
                  <StyledErrorMessage>
                    {errors.zipCode.message}
                  </StyledErrorMessage>
                )}
              </StyledFormItem>
            </StyledFormInputs>
            {textarea}
            <StyledCheckboxContainer>
              <StyledCheckboxLabel yledCheckboxLabel>
                <StyledFormCheckbox
                  {...register("policy", {
                    required: errorsMessage.required,
                  })}
                  type="checkbox"
                />
                <StyledCheckboxMsg>
                  {LANG.PL.forms.inputs.checkbox}
                  <StyledLinkButton
                    as={Link}
                    to={LANG.PL.forms.inputs.privacyPolicyURL}
                  >
                    {LANG.PL.forms.inputs.privacyPolicy}
                  </StyledLinkButton>
                  .
                </StyledCheckboxMsg>
              </StyledCheckboxLabel>
            </StyledCheckboxContainer>
            <StyledFormBtnBox>
              <Button type="submit">{LANG.PL.forms.inputs.sendBtn}</Button>
            </StyledFormBtnBox>
          </StyledForm>
        </StyledFormWrapper>
      )}
    </>
  );
};

export default Form;
