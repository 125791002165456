import styled from "styled-components";
import arrowIcon from "../../../../images/arrowIcon.png";

export const StyledFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 8.1%;
`;

export const StyledFormtitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.lighterBlack};
  margin: 0;
  padding-bottom: 3.7%;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const StyledForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
`;

export const StyledFormInputs = styled.div`
  height: 36%;
  width: 100%;
  display: grid;
  grid-template-columns: 48.7% 48.7%;
  grid-template-rows: 50% 50%;
  gap: 0 2.6%;
  @media only screen and (max-width: 425px) {
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StyledFormItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: relative;
`;

export const StyledFormLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  padding-bottom: 6px;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    text-align: center;
  }
`;
export const StyledFormInput = styled.input`
  background-color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-family: "Titillium Web", sans-serif;
  border: none;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 52px;
  padding-left: 10px;
  width: 100%;
  outline: none;
  border: solid 2px;
  border-color: ${({ theme }) => theme.colors.darkerGrey};
`;

export const StyledFormTextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding-top: 5.8%;
  position: relative;
  @media only screen and (max-width: 425px) {
    padding-top: 10%;
  }
`;

export const StyledFormTextArea = styled.textarea`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-family: "Titillium Web", sans-serif;
  background-color: ${({ theme }) => theme.colors.grey};
  border: none;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 108px;
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  outline: none;
  border: solid 2px;
  border-color: ${({ theme }) => theme.colors.darkerGrey};
  resize: none;
`;

export const StyledFormBtnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 140px;
  width: 300px;
  margin-left: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 110px;
    align-items: center;
  }
  @media only screen and (max-width: 425px) {
    width: 100%;

    margin-top: 20px;
  }
`;

export const StyledErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: red;
  position: absolute;
  bottom: -28%;
  left: 2%;

  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
    bottom: -40%;
    width: 100%;
    text-align: center;
  }
`;

export const StyledErrorTextArea = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: red;
  position: absolute;
  bottom: -20%;
  left: 2%;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
    bottom: -19%;
    width: 100%;
    text-align: center;
  }
`;

export const StyledMessageBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledMessage = styled.div`
  background-color: ${({ success, theme }) =>
    success ? theme.colors.success : theme.colors.error};
  color: #fff;
  border-radius: 20px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: center;
  padding: 30px 10px;
  min-height: 84px;
  width: 80%;
  max-width: 1020px;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledLinkButton = styled.button`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: block;
  margin: 10px auto 20px auto;
  width: 100%;
  max-width: 1020px;
  text-align: left;
`;

export const StyledCheckboxMsg = styled.span`
  margin-left: 23px;
  padding: 12px 0 26px;
  display: inline-block;
  width: calc(100% - 70px);
  vertical-align: middle;
  padding-top: 22px;
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.small};
  @media only screen and (max-width: 1130px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const StyledFormCheckbox = styled.input`
  &:checked {
    &:before {
      content: "\\2714";
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:before {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 28px;
    width: 28px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(44, 39, 56, 0.04);
    border: solid 2px;
    border-color: ${({ theme }) => theme.colors.lighterBlack};
  }
`;

export const StyledCheckboxLabel = styled.label`
  cursor: pointer;
`;
