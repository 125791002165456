import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {
  StyledAdressData,
  StyledFormAdress,
  StyledFormAdressBox,
  StyledFormtitle,
  StyledLocationIcon,
  StyledMap,
  StyledMapBox,
  StyledMapLabel,
  StyledMapWrapper,
} from "./map.style";
import { LANG } from "../../../../lang/lang";
import LocationIcon from "../../../../images/locationIcon.png";

const MapGoogle = () => {
  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 52.45512370133465, lng: 16.956027140740034 }}
    >
      <MarkerWithLabel
        position={{ lat: 52.45512370133465, lng: 16.956027140740034 }}
        labelAnchor={new google.maps.Point(0, 0)}
      >
        <StyledMapLabel>Voltspot</StyledMapLabel>
      </MarkerWithLabel>
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(MapGoogle));

const Map = (): JSX.Element => {
  return (
    <StyledMapWrapper>
      <StyledAdressData>
        <StyledFormtitle>{LANG.PL.contactSubpage.mapTitle}</StyledFormtitle>

        <StyledFormAdressBox>
          <StyledLocationIcon src={LocationIcon} />
          <StyledFormAdress>
            {LANG.PL.contactSubpage.mapAdressLine_1}
            <br />
            {LANG.PL.contactSubpage.mapAdressLine_2}
          </StyledFormAdress>
        </StyledFormAdressBox>
      </StyledAdressData>
      <StyledMapBox>
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAdFIQVHuXSZqZmn6kQlQZehGuTTTqO9yM`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<StyledMap />}
        />
      </StyledMapBox>
    </StyledMapWrapper>
  );
};

export default Map;
