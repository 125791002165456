import styled from "styled-components";

export const StyledMapWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const StyledAdressData = styled.div`
  display: flex;
  align-items: center;
  height: 21%;
  justify-content: space-between;
  padding-left: 9.5%;
  padding-right: 13.2%;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
`;

export const StyledFormtitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;

  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const StyledFormAdressBox = styled.div`
  display: grid;
  grid-template-columns: 11.8% 82.2%;
  width: 42%;
  gap: 0 20%;
`;

export const StyledFormAdress = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;

export const StyledLocationIcon = styled.img`
  height: 24px;
  width: 24px;
  margin: 12px 0;
  @media only screen and (max-width: 1613px) {
    margin: 24px 0;
  }
`;

export const StyledMapBox = styled.div`
  height: 79%;
  padding: 2%;
`;

export const StyledMap = styled.div`
  height: 100%;
  border-radius: 10px;
`;

export const StyledMapLabel = styled.div`
  width: 100px;
  height: 30px;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  background-color: #fafafa;
  color: #242424;
  font-size: 2rem;
  font-weight: 600;
`;
