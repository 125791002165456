import styled from "styled-components";

export const StyledContactFormWrapper = styled.section`
  margin-top: 11px;
  height: 1156px;
  @media only screen and (max-width: 1024px) {
    height: 1856px;
  }
`;

export const StyledContactFormContent = styled.div`
  padding: 0 149px;
  height: 100%;
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledContactFormBox = styled.div`
  height: 800px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: 54% 46%;
  @media only screen and (max-width: 1024px) {
    height: 1600px;
    display: flex;
    flex-direction: column;
  }
`;
export const StyledContactForm = styled.div`
  @media only screen and (max-width: 1024px) {
    height: 800px;
  }
  @media only screen and (max-width: 425px) {
    height: 1200px;
  }
`;
export const StyledContactMap = styled.div`
  @media only screen and (max-width: 1024px) {
    height: 800px;
  }
`;

export const StyledContactDivider = styled.a`
  text-decoration: none;
  height: 106px;
  max-width: 812px;
  margin: 150px auto 0 auto;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.lighterBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue};
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledContactFbIcon = styled.img`
  padding-right: 6.2%;
`;
