import React from "react";

import Layout from "../components/layout/layout.component";
import Footer from "../sections/footer/footer.component";
import SubpageHero from "../components/subpageHero/subpageHero.component";
import ContactForm from "../sections/subpageSections/contactSubpage/contactForm.component";
import SEO from "../components/seo.component";
import { LANG } from "../lang/lang";

const ContactPage = () => {
  return (
    <Layout>
      <SEO title={LANG.PL.contactSubpage.seoTitle} />
      <SubpageHero
        title={LANG.PL.contactSubpage.heroTitle}
        subTitle={LANG.PL.contactSubpage.heroSubTitle}
      >
        <ContactForm />
      </SubpageHero>
      <Footer />
    </Layout>
  );
};

export default ContactPage;
